






















import Vue from "vue";

import {Component, Emit, Prop} from 'vue-property-decorator';
import {TaxonomyBrief} from "@/service";

@Component
export default class TaxonomyFinder extends Vue {

  @Prop({default: [], required: true})
  readonly allSpecies!: TaxonomyBrief[];

  private speciesUrl(item: TaxonomyBrief) {

    // if (this.$route.name === 'TaxonomyEdit') {
    //   return `/list/${this.$route.params.list}/${item.ID}/edit`;
    // } else {
    return `/list/${this.$route.params.list}/${item.node}`;
    // }
  }
}
