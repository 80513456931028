export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function scaleVal(fn: (from: number) => number, val: number): number {
  return Math.round(fn(val) * 10) / 10;
}

export function scaleInvertVal(fn: (from: number) => number, val: number): number {
  return Math.round(fn(val) * 10) / 10;
}
