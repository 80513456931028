












































































































import Vue from "vue";
import {Component} from "vue-property-decorator";
import TaxonomyFinder from "@/components/TaxonomyFinder.vue";
import {RouteNext} from "@/types";
import {vxm} from "@/store";
import {searchTaxonomy} from "@/utils/searching";
import TaxonomyTree from "@/components/TaxonomyTree.vue";
import {Route} from "vue-router";
import {TaxonomyBrief} from "@/service";

@Component({
  components: {
    TaxonomyFinder,
    TaxonomyTree,
  },
})

export default class TaxonomyListPage extends Vue {

  private shouldSticky = false;
  private tocStyle = {
    overflowY: "hidden",
    maxHeight: "none"
  }
  private mainStyle = {
    minHeight: "none"
  }

  private searching = "";

  private taxonomyPage = vxm.taxonomyStore.taxonomyPage;

  get showSnackbar() {
    return vxm.main.snackbar.now || false;
  }

  set showSnackbar(val: boolean) {
    vxm.main.snackbar.now = val;
  }

  private snackbar = vxm.main.snackbar;

  private showDrawer: boolean = vxm.taxonomyStore.showDrawer;

  private toggleDrawer() {
    this.showDrawer = !this.showDrawer
    vxm.taxonomyStore.showDrawer = this.showDrawer;
  }

  private treeMode = vxm.taxonomyStore.treeMode;

  private toggleTreeMode() {
    this.treeMode = !this.treeMode
    vxm.taxonomyStore.treeMode = this.treeMode;


    if (this.treeMode) {
      vxm.taxonomyStore.loadTreeRoot(+this.$route.params.list);
    } else {
      vxm.taxonomyStore.loadAllSpecies(+this.$route.params.list);
    }
  }

  private allSpecies(): TaxonomyBrief[] {
    if (this.searching.length > 0) {
      return vxm.taxonomyStore.allSpecies.filter(v => searchTaxonomy(v, this.searching));
    } else {
      return vxm.taxonomyStore.allSpecies;
    }
  }

  private pageY(): number {
    return window.pageYOffset;
  }

  private headerHeight(): number {
    return this.$vuetify.application.top;
  }

  private onScroll(e: Event) {

    if (this.pageY() >= this.headerHeight()) {
      this.shouldSticky = true;
    } else {
      this.shouldSticky = false;
    }

    const headerObj = (this.$refs.header as Vue).$el;
    const tocObj = (this.$refs.toc as Vue).$el;
    const mainObj = (this.$refs.main as Vue).$el;
    const footerEl = (this.$refs.footer as Vue).$el;

    let tocHeight = window.innerHeight;
    const footerTop = footerEl.getBoundingClientRect().top;

    if (footerTop < tocHeight) {
      tocHeight -= (tocHeight - footerTop)
    }

    const newHeight = `${tocHeight}px`;
    // if (newHeight != this.tocStyle.maxHeight || this.tocStyle.overflowY != "auto") {
    //   this.tocStyle.maxHeight = newHeight;
    //   this.tocStyle.overflowY = 'auto';
    // }

    console.log(footerTop, newHeight)
  }


  private calcTocHeight() {
    const headerEl = (this.$refs.header as Vue).$el;
    const tocEl = (this.$refs.toc as Vue).$el;
    const mainEl = (this.$refs.main as Vue).$el;
    // const footerEl = (this.$refs.footer as Vue).$el;

    this.shouldSticky = this.pageY() >= this.headerHeight();

    console.log("page Y", window.pageYOffset)
    console.log("windows.innerHeight", window.innerHeight)
    console.log("header height", headerEl.getBoundingClientRect().bottom)
    // console.log("footer height", footerEl.getBoundingClientRect().top)

    const headerBottom = headerEl.getBoundingClientRect().bottom;
    // const footerTop = footerEl.getBoundingClientRect().top;
    let tocHeight = window.innerHeight;
    // tocHeight -= (footerTop < tocHeight) ? (tocHeight - footerTop) : 0;
    tocHeight -= (headerBottom > 0) ? headerBottom : 0;

    this.tocStyle.maxHeight = `${tocHeight}px`;

  }

  // @Watch('searching')
  // private onSearching(v: string) {
  //   const expUnit = "[\\s\u4E00-\u9FA5A-Za-z']*";
  //   const expMatch = new RegExp(expUnit + v.split('').join(expUnit) + expUnit);
  //
  //   console.log(v.length, expMatch.test("非洲鸵鸟fei zhou tuo niao"));
  // }

  private mounted() {
    this.calcTocHeight();
  }

  private created() {
    window.addEventListener("resize", this.calcTocHeight);
  }

  private destroyed() {
    window.removeEventListener("resize", this.calcTocHeight);
  }

  private beforeRouteEnter(to: Route, from: Route, next: RouteNext) {
    vxm.taxonomyStore.onListPage(+to.params.list);
    next();
  }

  private beforeRouteUpdate(to: Route, from: Route, next: RouteNext) {
    vxm.taxonomyStore.onListPage(+to.params.list);
    next();
  }
}
