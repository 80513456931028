















































import Vue from "vue";

import {Component, Prop, Watch} from 'vue-property-decorator';
import {vxm} from "@/store";
import {TaxonomyBrief} from "@/service";
import {delay} from "@/utils";

@Component
export default class TaxonomyTree extends Vue {

  private store = vxm.taxonomyStore;

  private activated: number[] = [];

  @Prop({default: false, required: true})
  readonly treeMode!: boolean;

  private get topOrders(): TaxonomyBrief[] {
    return vxm.taxonomyStore.treeRoot.children || [];
  }

  private onActive(item: TaxonomyBrief) {
    console.log("OnActive", item.kind, item.name, item.node);

    let to = `/list/${this.$route.params.list}/${item.node}`;

    if (to != this.$route.path) {
      this.$router.push(to);
    }
  }

  private async listTaxonomy(item: TaxonomyBrief) {
    await delay(300);
    return vxm.taxonomyStore.fetchTaxonomyChildren({
      list: +this.$route.params.list,
      node: item.node,
      target: item
    })
  }

  @Watch('treeMode')
  private onTreeModeChanged(val: string, oldVal: string) {
    console.log(`onTreeModeChanged`, val, oldVal);

    // vxm.main.loadData({to: this.$route, from: this.$route});
  }

}
